.chumphon-page .stick-img {
    position: fixed;
    background-size: 100%;
    width: 100%;
    height: 900px;
    margin-top: -130px;
    background-image: url(../img/chumphon.jpg);
    z-index: -2;
    min-width: 1280px;
}

.chumphon-page .container {
    position: relative;
    top: 452px;
    height: fit-content;
    margin-bottom: 452px;
}

.tittle-box {
    background: white;
    padding: 30px 15px;
    color: #18C1C0;
}

.mainbox {
    background: white;
    padding: 15px;
    color: black;
    margin-top: 20px;
}

.chumphon-page img {
    width: 100%;
    height: auto;
}

.chumphon-page .col-text,
.chumphon-page .col-vid,
.chumphon-page .col-img,
.chumphon-page .col-table {
    padding: 20px;
    font-size: 18px;
    line-height: 1.8;
}

.chumphon-page p {
    color: black;
}

.link-btn {
    font-size: 30px;
    padding: 20px;
    color: #18C1C0;
    font-weight: bold;
}

.link-btn span {
    font-size: 20px;
    position: relative;
    top: -3px;
}

.mainbox .line-break2 {
    margin-bottom: 20px;
}

.chumphon-page-img2,
.chumphon-page-img3 {
    padding-top: 20px;
}

.chumphon-page .col-vid iframe {
    width: 100%;
    height: 250px;
}

.tsp-icon {
    background-color: white;
    border: 4px solid white;
    height: 60px;
    width: 60px;
    box-shadow: 0px 1px 5px gray;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: white;
}


.transport-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.tsp-icon.plane {
    background-color: #fa6e53;
    font-size: 26px;
}

.tsp-icon.train {
    background-color: #aa4e9a;
    font-size: 24px;
}

.tsp-icon.bus {
    background-color: #48d0ae;
}

.tsp-icon.boat {
    background-color: #ffb605;
}

.tsp-icon.taxi {
    background-color: #00afec;
    font-size: 25px;
}

.ticket-btn {
    border-radius: 4px;
    color: white;
    background: #18C1C0;
    padding: 10px;
    line-height: 1.3;
    box-shadow: 0px 1px 5px gray;

}

.tsp-icon:hover {
    box-shadow: inset 0px 1px 5px white;
}

.ticket-btn:hover {
    background: rgba(255, 183, 0, 1);
}

.plane-table {
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
}

table {
    background-color: #a0a09f;
    color: white;
    font-weight: bold;
    width: 100%;
}

td, th {
    border: 1px solid white;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    text-align: left;
}

th {
    text-align: center;
}

tr:nth-child(even) {
    background-color: #f5f5f5;
    color: black;
    font-weight: bold;
}

tr:nth-child(odd) {
    background-color: #e3e3e3;
    color: black;
    font-weight: bold;
}

th {
    background-color: #a0a09f;
    color: white;
}

.chumphon-page .col-table {
    padding-top: 0;
}
.chumphon-page .col-vid {
    padding-bottom: 0;
}
.row-2img .col-lg-6:first-of-type {
    padding-right: 5px;
}
.row-2img .col-lg-6:nth-of-type(2) {
    padding-left: 5px;
}

.chumphon-page .see-accom-button {
    font-size: 25px;
}


@media (min-width: 1200px) {
    .chumphon-pageus-img5 {
        display: none;
    }

}

@media (max-width: 1199px) {
    .chumphon-page .container {
        position: relative;
        top: 200px;
        height: fit-content;
        margin-bottom: 200px;
    }

    .chumphon-pageus-img5 {
        display: block;
    }

}

@media (max-width: 991px) {
    .chumphon-page .container {
        position: relative;
        top: 50px;
        height: fit-content;
        margin-bottom: 50px;
    }

    .chumphon-page .col-text,
    .chumphon-page .col-vid,
    .chumphon-page .col-img,
    .chumphon-page .col-table {
        padding: 20px 0 0 0;
    }

    .chumphon-page .col-vid,
    .chumphon-page .col-img {
        padding-left: 20px;
    }
    .col-next-tran {
        display: none;
    }
    .col-tran-box {
        flex: 0 0 100%;
    }
    .row-2img .col-lg-6 {
        padding-bottom: 10px;
    }
    .row-2img .col-lg-6:first-of-type {
        padding-right: 0;
    }
    .row-2img .col-lg-6:nth-of-type(2) {
        padding-left: 0;
    }

}

@media (max-width: 767px) {

    .chumphon-page .col-vid,
    .chumphon-page .col-img {
        padding-left: 0px;
    }

}
@media (max-width: 460px) {
    .col-tran, .ticket-btn {
        display: flex;
        flex: 0 0 33.33%;
        justify-content: center;
        text-align: center;
    }
    .col-tran:nth-child(-n+3) {
        margin-bottom: 20px;
    }
}
@media (max-width: 1199px) {
   .text-chumphon2 {
    display: flex;
   }
   .text-chumphon1 {
    display: none;
   }

}
@media (min-width: 1200px) {
    .text-chumphon2 {
     display: none;
    }
    .text-chumphon1 {
        display: flex;
       }
 
 }
 @media (max-width: 300px) {
    th, td {
        padding: 0;
        text-align: center;
    }
 }