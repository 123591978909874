.accom-icon {
    height: 40px;
    margin-bottom: 13px;
}

.medcare-icon {
    height: 52px;
    margin-bottom: 6px;
    margin-top: -5px;
}

.emergency-icon {
    height: 40px;
    margin-bottom: 13px;
}

.visa-icon {
    height: 40px;
    margin-bottom: 15px;
}

.housekeeping-icon {
    height: 45px;
    margin-bottom: 10px;
}

.meal-icon {
    width: 42px;
    height: auto;
    margin-bottom: 17px;
    margin-top: 13px;
}

.icon-con .col {
    min-width: 150px;
}

.green-box, .green-box2 {
    background-color: #62a19d;
    display: table;
    height: 100%;
    max-height: 275px;
    width: 100%;
    padding: 20px;
    text-align: center;
}

.green-box2 {
    min-height: 309px;
}

.box-text {
    display: table-cell;
    vertical-align: middle;
    color: white;

}

#line-break-box {
    position: relative;
    width: 60px;
    height: 3px;
    background-color: rgba(255, 183, 0, 1);
    margin-left: auto;
    margin-right: auto;
    margin-top: 12%;
}

.accom-img1, .location-img {
    overflow: hidden;
    max-height: 275px;
    padding: 0px;
    margin-left: 0px;
    background-color: white;
}

.location-img {
    height: 100%;
}

.accom-img1 img {
    width: 100%;
    margin-top: -60px;
    height: auto;
}

.accom-text {
    padding: 30px;
}

.box-text2 {
    color: black;

}

.accom .col {
    display: flex;
}

.see-accom-button {
    font-size: 20px;
    font-weight: bold;
    color: #21968F;
    text-align: left;
    font-weight: bolder;
    padding-top: 30px;
}

.arrow-icon-thin {
    font-size: 13px;
    display: inline-flex;
}

.see-accom-button:hover {
    color: rgba(255, 183, 0, 1);
}

.tch-img {
    width: 110%;
    height: auto;
}

.tch h1, .tch p {
    color: white;
}

.tch h1 {
    font-size: 34px;
}

.tch .see-accom-button {
    color: white;
    padding-top: 10px;
}

.tch .col-md-5 {
    overflow: hidden;
}

.to-do-icons {
    width: 61px;
    height: auto;
}

.things-to-do-box .col-sm-4 {
    padding-bottom: 60px;
}

.boat-icon {
    padding: 14.5px 0;
}

.cave-icon {
    padding: 11.4px 7px;
}

.booknow-container {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    background-image: url(../img/booknow-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.booking-content > p{
    font-size: 25px;
    color:black;
    text-shadow: 1px 1px 4px white;
    padding-top: 25px;
    text-align: center;

}
.booknow-con{
    max-width: 625px;
    height: fit-content;
    margin-right: auto;
    margin-left: auto;
}



@media screen and (max-width: 413px) {
    .green-box {
        padding: 20px 0;
    }
}

@media (max-width: 575px) {
    .things-to-do-box .col-sm-4 {
        flex: 0 0 auto;
        width: 50%;
    }
}