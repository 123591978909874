.carousel {
    position: unset;
}
.carousel-inner {
    max-height: 719px;
}
.carousel-indicators {
    display: none;
}
.carousel-caption {
    position: unset;
    color: #fff;
    text-align: center;
}
.slide1text {
    width:100%;
    font-family: 'Cormorant Garamond',times, serif;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
    font-size:70px;
    color: white;
    position: absolute;
    font-style: italic;
    margin-left: auto;
    margin-right: auto;
    top: 520px;
    font-weight: 500;
}
.slide1text2{
    font-family: 'Brandon Grotesque','Century Gothic',Arial,Helvetica,sans-serif;
    font-size: 23px;
    font-style: none;
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    top: -55px;
    font-weight: 600;
}
.slide2container{
    width: 414px;
    height: 454px;
    background-color: rgba(98, 98, 98, 0.5);
    position: absolute;
    top:137px;
    left:155px;
}
.slide2text{
    font-family: 'Cormorant Garamond',times, serif;
    text-align: center;
    font-size:50px;
    color: white;
    position: absolute;
    font-style: italic;
    border-style:solid;
    border-width: 2.5px;
    border-color: white;
    margin: 24px;
    padding: 51px 35px;
    font-weight: 500;
    left: 0;
}

.slide3text{
    font-family: 'Cormorant Garamond',times, serif;
    text-align: center;
    font-size:55px;
    color: #15C2B8;
    position: absolute;
    font-style: italic;
    margin: 24px;
    padding: 51px 35px;
    top: 90px;
    left: 40px;
    font-weight: 500;
}

.slide1 img {
    width: 100%;
}
@media screen and (max-width: 1319px) {
    .carousel {
        display: none;
    }
    .slide1 {
        display: block;
    }
}
@media screen and (min-width: 1320px) {
    .slide1 {
        display: none;
    }
}