.nav-area {
    background-color: rgba(35, 35, 35, 0.6);
    width: 100%;
    height: fit-content;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 15px 0;
}

.nav-area .row {
    display: flex;
    justify-content: space-between;
}

.logo-area {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    /* min-width: 321px; */
}

.logo-con {
    height: 55px;
    width: 55px;
    max-width: 70px;
    width: fit-content;
}


.logo-area span {
    display: block;
}

.logo-text {
    padding: 0 10px;
}

.logo-text .t2 {
    font-family: 'Cormorant Garamond', times, serif;
    font-weight: bold;
}

.logo-text .t1 {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.1em;
}

.row-nav {
    flex-wrap: nowrap;
}

.row-nav>* {
    width: fit-content;
}

.mainnav {
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-weight: bold;
}

.mainnav ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: 0 0;
    display: flex;
    margin-top: 10px;
}

.mainnav li {
    list-style-type: none;
    list-style: none;
    display: flex;
    font-size: 15px;
    padding: 0 20px;
}

.mainnav li,
.moreNav li {
    list-style-type: none;
    list-style: none;
}

.mainnav a {
    line-height: 3;
}

.mainnav li:hover,
.moreNav li:hover,
.nav-item .active,
.active .nav-item {
    border-top-style: solid;
    border-top-width: 3px;
    border-top-color: rgba(255, 183, 0, 1);
    color: #2BE0D6 !important;
    text-decoration: none;
    margin: -3px 0 0 0;
}

.mainnav .nav-item {
    text-decoration: none;
}

.moreNav {
    display: block;
    position: fixed;
    background-color: rgba(35, 35, 35, 0.6);
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 15px;
    line-height: 3.5;
    top: 85px;
    left: 68%;
    text-align: center;
    z-index: 1;
}

.moreItem {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.booknow-button-nav {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
    padding: 0 10px;

}

.booknow-button-nav a {
    text-decoration: none;
    color: white;
}

.booknow-button {
    border: solid rgba(255, 255, 255, 1) 1px;
    padding: 10px;

}

.booknow-button:hover {
    border: solid rgba(255, 183, 0, 1) 1px;
    color: #2BE0D6;
}

.mobile-menu-button {
    color: white;
}

.mobileDropdown {
    display: block;
}

button.mobileMenuGo {
    display: block;
    width: 100%;
    border: none;
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
    margin: 5px 0;
    background: lightgray;
}

@media screen and (min-width: 1199px) {
    .mobile-menu {
        display: none;
    }

}

@media screen and (max-width: 1199px) {
    .booknow-button-nav, .mainnav, .moreNav {
        display: none;
    }

    .mobile-menu {
        display: flex;
        align-items: center;
        font-size: 30px;
        padding: 0;
        margin: 0;
        line-height: 0;
    }

    .nav-area {
        position: inherit;
    }

}

@media screen and (max-width: 380px) {
    .mobile-menu, .logo-area {
        padding: 0;
    }
}

@media screen and (max-width: 374px) {
    .logo-con {
        height: 45px;
    }

    button.mobileMenuGo {
        font-size: 18px;
    }

    .mobile-menu {
        padding: 0;
    }

    .logo-area {
        padding: 0;
    }
}

@media screen and (max-width: 359px) {
    .logo-area {
        padding: 0;
    }

    .logo-con {
        height: 35px;
    }

    .logo-text .t1 {
        font-size: 14px;
    }

    .logo-text .t2 {
        font-size: 11px;
    }
}