.form-group, .form-group-fix {
    position: relative;
    margin-bottom: 12px;
}
.form-group-fix {
    max-height: 45px;
}

.form-label,
.form-label-fix {
    position: absolute;
    top: 10px;
    left: 15px;
    color: #7a7a7a;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    font-size: 18px;
}

.form-input,
.form-input-fix {
    width: 100%;
    padding: 15px 15px 3px 14px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
}

.form-input:focus+.form-label,
.form-input:not(:placeholder-shown)+.form-label {
    top: 3px;
    font-size: 12px;
    color: #7a7a7a;
}

.form-label-fix {
    top: 3px;
    font-size: 12px;
    color: #7a7a7a;
}

.cares {
    color: black;
    background-color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    font-style: italic;
    border: none;
    padding: 15px;
    line-height: 1.5;
    background-color: rgba(255, 255, 255, 0.8);
}

.cares label {
    padding-left: 10px;
    display: inline;
}

.booknow-form input[type=submit],
.booknow-form input[type=reset] {
    background-color: #18C1C0;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
    border: 2px solid #18C1C0;

}

.booknow-form input[type=submit]:hover {
    background-color: rgba(255, 183, 0, 1);
    border: 2px solid rgba(255, 183, 0, 1);

}

.booknow-form input[type=reset] {
    background-color: transparent;
    color: white;
    border: 2px solid #18C1C0;

}

.booknow-form input[type=reset]:hover {
    border: 2px solid rgba(255, 183, 0, 1);

}

.booknow-con select {
    position: relative;
    top: -27px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    background: transparent;
    margin-left: 10px;
    width: 93%;
}
.swal2-confirm {
    background-color: #18C1C0 !important; 
    box-shadow: none !important;
    font-weight: bold;
}


@media (min-width: 768px) {
    .booknow-con .col-md-6 {
        flex: 0 0 auto;
        width: 49%;
    }

    .booknow-con .col-md-4 {
        flex: 0 0 auto;
        width: 32%;
    }
}

@media (min-width: 768px) {
    .booknow-con .row {
        display: flex;
        justify-content: space-between;
    }
}