.accom-con {
    max-width: 872.2px;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding: 15px;
}

.long-linebreak {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 183, 0, 1);
    margin: 10px 0 35px 0;
}

.accom-con .row {
    justify-content: space-between;
    margin-top: 15px;
}

.slick-prev,
.slick-next {
    z-index: 1;
}

.slick-prev {
    left: 5px;
}

.slick-next {
    right: 5px;
}

.slick-dots {
    bottom: 15px;
}

.slick-dots li {
    width: 10px;
}

.price-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.property-button {
    background-color: #18C1C0;
    width: 100%;
    max-width: 142px;
    height: 40px;
    padding-top: 7px;
    text-align: center;
}

.property-button:hover {
    background-color: rgba(255, 183, 0, 1);
}

.property-button h3 {
    color: white;
    font-size: 20px;
}

.line-gray {
    background-color: #DBDBDB;
    margin: 25px 0px 12px 0;
}

.map-moreinfo {
    font-size: 25px;
}

.moreinfo {
    text-align: center;
}

.accom-intro-box p {
    margin-top: 20px;
}

.accom-intro-box h3 {
    font-size: 25px;
}

.accom-row {
    margin-bottom: 25px;

}
.viewmap:hover,
.moreinfo:hover {
    color: black;
}
.moreinfo, .property-button {
    cursor: pointer;
}


@media (min-width: 768px) {
    .accom-con .col-md-6 {
        width: 40%;
    }
    .accom-con .col-md-4 {
        flex: 0 0 auto;
        width: 34%;
    }
}


@media (max-width: 1199px) {
    .accom-con {
        margin-top: 0px;
    }
}

@media (max-width: 767px) {
    .accom-intro-box {
        margin-top: 20px;
    }

    .viewmap {
        text-align: center;
    }

    .price-box {
        flex-direction: row;
        align-items: center;
    }

    .price-box .col-12 {
        display: flex;
        justify-content: center;
    }

    .price-box p, .price-box h1 {
        display: flex;
        flex: 0 0 50%;
    }

    .price-box p {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
    }

    .price-box .row {
        align-items: center;
    }
}