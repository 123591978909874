.about .stick-img {
    position: fixed;
    background-size: 100%;
    width: 100%;
    height: 900px;
    margin-top: -130px;
    background-image: url(../img/aboutus.jpg);
    z-index: -2;
    min-width: 1280px;
}

.about .container {
    position: relative;
    top: 452px;
    height: fit-content;
    margin-bottom: 452px;
}

.tittle-box {
    background: white;
    padding: 30px 15px;
    color: #18C1C0;
}

.mainbox {
    background: white;
    padding: 15px;
    color: black;
    margin-top: 20px;
}

.about img {
    width: 100%;
    height: auto;
}

.col-text {
    padding: 20px;
    font-size: 18px;
}

.sub-tittle {
    padding: 20px 20px 0 20px;
    font-size: 30px;
    color: #18C1C0;
}

.mainbox .line-break2 {
    margin-bottom: 20px;
}

.aboutus-img1,
.aboutus-img2,
.aboutus-img5 {
    padding-top: 20px;
}
@media (min-width: 1200px) {
    .aboutus-img5 {
        display: none;
    }

}

@media (max-width: 1199px) {
    .about .container {
        position: relative;
        top: 200px;
        height: fit-content;
        margin-bottom: 200px;
    }
    .aboutus-img5 {
        display: block;
    }

}
@media (max-width: 991px) {
    .about .container {
        position: relative;
        top: 50px;
        height: fit-content;
        margin-bottom: 50px;
    }
    .aboutus-img2,
    .aboutus-img5 {
        display: none;
    }

}