.resort-con {
    max-width: 1000px;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding: 15px;
    color: black;
}

.resort-con img {
    width: 100%;
}

.return-arrow {
    color: rgba(255, 183, 0, 1);
    font-size: 35px;
    display: flex;
    padding: 2px 15px 0 0;
}

.resort-title {
    display: flex;
}

.resort-title h1 {
    color: #18C1C0;
}

.thumbnail {
    padding-right: 10px;
}

.slider-wrapper {
    overflow: hidden;
}

.slick-list {
    width: 102%;
}

.resort-detail {
    margin-top: 20px;
}

.customise-form-area {
    padding: 0 0 20px 20px;
}

.resort-detail p {
    line-height: 1.7;
}

.facilities-con {
    text-align: center;
    padding: 40px 20px 20px 20px;
    margin: 20px 0;
    background: white;
}

.facilities-con h2 {
    color: #7A7A7A;
    margin-bottom: 30px;
}

.resort-con .icon {
    height: 45px;
    width: auto;
    margin-bottom: 5px;
}

.facilities-con .col-md-2 {
    margin-bottom: 30px;
}

@media (max-width: 1199px) {
    .resort-con {
        margin-top: 0px;
    }
}

@media (max-width: 767px) {
    .customise-form-area {
        padding: 0;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 575px) {
    .resort-con .col-xs-6 {
        flex: 50%;
        flex: 0 0 50%;
    }

    .facilities-con {
        padding: 40px 0 20px 0;
    }
}