.contact-area{
    background-color: #f5f5f5;
    width: 100%;
    color: black;
}
.contact-container {
    align-content: center;
    text-align: center;
    font-weight: bold;
    max-width: 625px;
    background-color: #f5f5f5;
}
.contact-container .row {
    justify-content: space-between;
    margin-top: 30px;
}
.contact-container .line-break2 {
    margin-left: auto;
    margin-right: auto;
}
.swal2-confirm {
    background-color: #18C1C0 !important; 
    box-shadow: none !important;
    font-weight: bold;
}
.form-group {
    position: relative;
    margin-bottom: 12px;
}

.form-label {
    position: absolute;
    top: 10px;
    left: 15px;
    color: #7a7a7a;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    font-size: 18px;
}

.form-input {
    width: 100%;
    padding: 15px 15px 3px 14px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
}

.form-input:focus+.form-label,
.form-input:not(:placeholder-shown)+.form-label {
    top: 3px;
    font-size: 12px;
    color: #7a7a7a;
}

.contact-form input[type=submit],
.contact-form input[type=reset] {
    background-color: #18C1C0;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
    border: 2px solid #18C1C0;

}
.contact-form input[type=submit],
.contact-form input[type=reset] {
    background-color: #18C1C0;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
    border: 2px solid #18C1C0;

}

.contact-form input[type=submit]:hover {
    background-color: rgba(255, 183, 0, 1);
    border: 2px solid rgba(255, 183, 0, 1);

}

.contact-form input[type=reset] {
    background-color: transparent;
    color: #18C1C0;
    border: 2px solid #18C1C0;

}

.contact-form input[type=reset]:hover {
    border: 2px solid rgba(255, 183, 0, 1);
    color: rgba(255, 183, 0, 1);

}
.form-group-message textarea {
    min-height: 200px;
}
.contact-form .form-input {
    background-color: rgba(255, 255, 255, 1);
}
@media (min-width: 768px) {
    .contact-form .col-md-6 {
        flex: 0 0 auto;
        width: 49%;
    }
}