.tch-page .stick-img {
    position: fixed;
    background-size: 100%;
    width: 100%;
    height: 900px;
    margin-top: -130px;
    background-image: url(../img/tch-bg.jpg);
    z-index: -2;
    min-width: 1280px;
}

.tch-page .container {
    position: relative;
    top: 452px;
    height: fit-content;
    margin-bottom: 452px;
}

.tittle-box {
    background: white;
    padding: 30px 15px;
    color: #18C1C0;
}

.mainbox {
    background: white;
    padding: 15px;
    color: black;
    margin-top: 20px;
}

.tch-page img {
    width: 100%;
    height: auto;
}

.tch-page .col-text,
.tch-page .col-img {
    padding: 20px;
    font-size: 18px;
    line-height: 1.8;
}

.tch-page p {
    color: black;
    margin-bottom: 1.6em;

}
.link-btn {
    font-size: 30px;
    padding: 20px;
    color: #18C1C0;
    font-weight: bold;
}
.link-btn span {
    font-size: 20px;
    position: relative;
    top: -3px;
}

.mainbox .line-break2 {
    margin-bottom: 20px;
}

.tch-img2,
.tch-img3 {
    padding-top: 20px;
}

@media (min-width: 1200px) {
    .tch-pageus-img5 {
        display: none;
    }

}

@media (max-width: 1199px) {
    .tch-page .container {
        position: relative;
        top: 200px;
        height: fit-content;
        margin-bottom: 200px;
    }

    .tch-pageus-img5 {
        display: block;
    }

}

@media (max-width: 991px) {
    .tch-page .container {
        position: relative;
        top: 50px;
        height: fit-content;
        margin-bottom: 50px;
    }

    .tch-page .col-text,
    .tch-page .col-img {
        padding: 0 0 0 0;
    }
    .tch-page .col-img {
        padding-left: 20px;
    }

}
@media (max-width: 767px) {
    .tch-page .col-img {
        padding-left: 0px;
    }
    .link-btn {
        font-size: 25px;
        padding: 20px 0;
    }

}