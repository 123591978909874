@import '~bootstrap/dist/css/bootstrap.min.css';

body {
    font-family: 'Josefin Sans', 'Century Gothic', Arial, Helvetica, sans-serif;
    background: #f5f5f5;
    color: #7A7A7A;
    font-weight: bold;
}

*:focus {
    outline: none;
}

a {
    font-style: none;
    text-decoration: none;
    color: inherit;
}

p {
    font-size: 18px;
}

img {
    height: 100%;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left !important;
}

.main-container, .facilities-container {
    height: fit-content;
    max-width: 1011.2px;
    margin-left: auto;
    margin-right: auto;
    background: white;
    font-weight: bold;
}

#break-box,
#break-box-clear {
    height: 50px;
    width: 100%;
    background-color: #f5f5f5;
}

#break-box-clear {
    background-color: transparent;
}

h1, h2, h3, h4, h5, h6, h7, p {
    font-weight: bold;
}

.first-box {
    position: relative;
    background-color: white;
    height: fit-content;
    text-align: center;
    padding: 10px;
}

.first-box>h2 {
    text-align: center;
    padding-top: 3%;
}

.line-break {
    position: relative;
    width: 60px;
    height: 3px;
    background-color: rgba(255, 183, 0, 1);
}

.line-break1 {
    margin: 3% auto;
}

.line-break2 {
    margin-top: 2%;
    margin-bottom: 3%;
}

.line-break3 {
    top: -12px;
    left: 47%;
}

.line-break4 {
    margin-top: 5px;

}

.first-box h4 {
    font-size: 20px;
    line-height: 1.5;
}

.first-box h3 {
    padding-top: 65px;
    padding-bottom: 30px;
    color: black;
    font-size: 25px;
}

.first-box h5 {
    color: black;
}

.row {
    --bs-gutter-x: 0;
}

.w100 {
    width: 100%;
}
.red {
    color: red;
}
.center-row {
    display: flex;
    align-items: center;
}



@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.mainbody {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #F5F5F5;
    top: 700px;
    margin-bottom: -700px;
}