.right-reserved {
    background: black;
    text-align: center;
    width: 100%;
    color: white;
    bottom: 0;
    height: 60px;
}
.right-reserved .col-12 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-reserved p {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: bold;
}