.customise-con {
    border: 3px solid rgba(255, 183, 0, 1);
    max-width: 490px;
}

.customise-con .title {
    text-align: center;
    padding: 20px 10px 0px 10px;
    color: #7A7A7A;
}

.customise-con .title h2 {
    color: #18C1C0;
    font-weight: bold;
}

.customise-con .line-break2 {
    margin: 0 auto 20px auto;
}

.customise-con .cares {
    background: none;
}

.customise-con .row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-top: 0;
}

.customise-con .cares input[type="checkbox"] {
    max-width: 14px;
}

.customise-con .cares {
    font-size: 17px;
    font-style: normal;
    padding-bottom: 0 !important;
}

.number-text {
    font-size: 14px;
}

.customise-con .col-1,
.customise-con .col-11 {
    padding: 0 0 10px 0;
}

.customise-con select {
    position: relative;
    top: -27px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    background: transparent;
    margin-left: 10px;
    width: 93%;
}

.customise-con .form-input-fix,
.customise-con .form-input-email {
    height: 43px;
    border: 1px solid #a0a09f;
    border-radius: 7px;
}

.form-input-email {
    padding: 15px 15px 3px 14px !important;
}

.customise-con .select {
    font-size: 17px;
}

.customise-con input[type=submit] {
    background-color: #18C1C0;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
    border-radius: 7px;
}

.customise-con input[type=submit]:hover {
    background-color: rgba(255, 183, 0, 1);
}

.customise-con .cares label {
    padding-left: 0;
}

